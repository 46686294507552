import Hero from './components/Hero/Hero';
import Intro from './components/Intro/Intro';
import CTA from './components/CTA/CTA2';
import Metrics from './components/Metrics/Metrics';
import Testimonial from './components/Testimonial/Testimonial';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import './App.css';

function App() {
  return (
    <div className="App">
      <Hero id="home" />
      <Intro id="about" />
      <Testimonial />
      <Metrics />
      <CTA />
      <Contact id="contact" />
      <Footer />
    </div>
  );
}

export default App;
