const CTA = () => {
    return (
      <div className="h-80 bg-gradient-to-r from-sky-500 via-indigo-500 to-pink-500" >
        <div className="relative max-w-7xl h-80 text-center items-middle align-center mx-auto justify-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex ">
            <div className="flex flex-col mx-auto h-full max-w-lg text-center items-center justify-center">
                <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">Ready to dive in?</h2>
                <p className="mt-4 text-lg tracking-tight text-white">Schedule a demo with us & if your happy the first three months are on us.</p>
                <a href="#contact" className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-8">Get 3 Months Free</a>
            </div>
        </div>
      </div>
    )
}

export default CTA;