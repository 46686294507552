import React from 'react';
import { SwitchHorizontalIcon, GlobeAltIcon, CloudIcon, AnnotationIcon, UserGroupIcon, ChartSquareBarIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'BonusIT Management System',
    description:
      'You set the parameters and we manage the rest. Provide employees with clear objectives & reward them for achieving their goals!',
    icon: GlobeAltIcon,
  },
  {
    name: 'Fully Automated',
    description:
    'Our system works behind the scenes with no additional human input.',
    icon: CloudIcon,
  },
  {
    name: 'Seamless Integration',
    description:
    'Your DMS already has all of the information we require, which makes integration a breeze.',
    icon: SwitchHorizontalIcon,
  },
  {
    name: 'Admin/Employee Dashboard',
    description:
    'Provides both managers & employees with a way to visually see their targets, performance and current progess. ',
    icon: ChartSquareBarIcon,
  },
  {
    name: 'Languageless',
    description:
    'Simple display screens are designed to convey all necessary data by using images to display information, meaning employees of any language can use the system.',
    icon: AnnotationIcon,
  },
  {
    name: 'Transparancy & Fairness',
    description:
    "An employee's bonus directly correlates to their contribution to the business.",
    icon: UserGroupIcon,
  }
]

const Intro = () => {
  return (
    <>
      <div className="py-12 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <p className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About Us
            </p>
            <p className="mt-4 max-w-4xl text-xl text-gray-700 lg:mx-auto">
              In today's business world productivity is paramount to the success and longevity for any business, corporation or organisation.
            </p>
            <p className="mt-4 max-w-4xl text-xl text-gray-700 lg:mx-auto">
              Increasing employee engagement leads to higher productivity, retention and morale and is highlighted as one of the most powerful tools in creating and sustaining a profitable business.
            </p>
            <p className="mt-4 max-w-4xl text-xl text-gray-700 lg:mx-auto">
              We have created a system in which employees can view their individual progress and are rewarded based on their percentage of company performance.     
            </p>
          </div>
        </div>
      </div>

      <div className="py-8 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What We Offer
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            With over 30 years expertise within the automotive industry, we realised the lack of a sufficient employee performance management system.         
          </p>
          <h2 className="mt-4 text-base text-indigo-600 font-semibold tracking-wide uppercase">So we created our own.</h2>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    </>
  )
}

export default Intro;
