import React from 'react';

import workshop from '../../static/workshop.png';
import manager from '../../static/manager.png';
import employee from '../../static/emp.png';

const callouts = [
    {
      name: 'Manager Dashboard',
      description: 'View your employee’s individual targets, VHC averages and totals, manage your bonus parameters, users and generate monthly reports. ',
      imageSrc: manager,
      imageAlt: 'BonusIT Manager Dashboard',
    },
    {
      name: 'Workshop Dashboard',
      description: 'A dashboard for your workshop. Allows employees to see where they are relative to others, while keeping them anonymous via codenames to ensure the worst and best are not ostracized.',
      imageSrc: workshop,
      imageAlt: 'BonusIT Employee Dashboard',
    },
    {
      name: 'Employee Dashboard',
      description: 'Each employee has their own individual dashboard which visualises their monthly performance and keeps them updated on their progress relative to their target.',
      imageSrc: employee,
      imageAlt: 'BonusIT Workshop Dashboard',
    },
];
  
const Metrics = () => {
    return (
      <div className="bg-white mb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto lg:max-w-none">
            <div class="container px-5 py-12 mx-auto text-gray-600 body-font">
              <div class="flex flex-col text-center w-full">
                <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Our System
                </h1>              
              </div>
              
              <div class="flex justify-center flex-wrap text-center">
                <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                  <h2 class="text-lg sm:text-xl text-indigo-600 font-semibold title-font">Automatic Targets</h2>
                  <p class="leading-relaxed text-base mb-4">Automatically sets the target each month for individual technicians and overall workshop.</p>
                </div>
                <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                  <h2 class="text-lg sm:text-xl text-indigo-600 font-semibold title-font mb-2">Performance Monitoring</h2>
                  <p class="leading-relaxed text-base mb-4">Keeps manager and technicians informed on a daily basis as to their performance relative to their target.</p>
                </div>
                <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
                  <h2 class="text-lg sm:text-xl text-indigo-600 font-semibold title-font mb-2">Calculating Bonus</h2>
                  <p class="leading-relaxed text-base mb-4">Calculates the bonus payment at the end of the month for each technician and generates a report for manager sign off.</p>
                </div>
                <div class="border-l-2 border-gray-200 border-opacity-60"></div>
              </div>
            </div>

            <div className="space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative w-full h-60 sm: h-80 bg-white rounded-lg overflow-hidden group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:h-52">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="w-full h-60 sm:h-full object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-base font-semibold text-indigo-600">
                      <span className="absolute inset-0" />
                      {callout.name}
                  </h3>
                  <p className="text-base text-gray-900">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
}
  

export default Metrics;